// import allUnitGroupAttributesMock from "../_mock/api/allUnitGroupAttributesMock";
import { Locale } from "nextjs-routes";
import { AggregationApiClient } from "./aggregation-client";

export const fetchAllUnitGroupAttributes = (p?: { language: Locale }) => {
  // if (process.env.NEXT_PUBLIC_APP_ENV === "mock") {
  //   return Promise.resolve(allUnitGroupAttributesMock);
  // }
  return AggregationApiClient.HotelUnitGroupService.hotelUnitGroupControllerFetchHotelUnitGroupAttributes({
    perPage: 99,
    language: p?.language || "ru",
  })
    .then((items) => {
      return items
        .sort((a, b) => a.priority - b.priority)
        .map((item) => {
          return {
            ...item,
            attributes: item.attributes.sort((a, b) => a.priority - b.priority),
          };
        });
    })
    .catch((__err) => {
      return [];
    });
};
